import React from 'react';
import { useTranslation } from 'react-i18next';

import { Settings } from '../../utils/Settings';

const SessionInfoForm = (props) => {
	const { readOnly, session = null } = props;

	const { t } = useTranslation();

	if (session == null) return null;

	return (
		<form>
			<div className="row">
				<div className="col-xs-12 col-sm-6 mb-3">
					<label className="mb-0">{t('cp.chargePointUsed')}</label>
					<input
						readOnly={readOnly || false}
						className="form-control form-control-sm "
						type="text"
						value={`${session.chargePointLocation ?? ''} (${session.chargePointPhysicalReference ?? session.chargePointDeviceId})`}
					/>
				</div>
				<div className="col-xs-12 col-sm-6 mb-3">
					<label className="m-0"> {t('cp.chargeCardDescriptionVisibleNumber')}</label>
					<input
						readOnly={readOnly || false}
						className="form-control form-control-sm"
						type="text"
						value={`${session.chargeTagDescription ?? ''} (${session.chargeTagVisibleNumber ?? ''})`}
					/>
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12 col-sm-6 mb-3">
					<label className="m-0">{t('cp.sessionStarted')}</label>
					<input readOnly={readOnly || false} className="form-control form-control-sm" type="text" value={Settings.formatDate(session.transactionDateStarted, 'datetime')} />
				</div>
				<div className="col-xs-12 col-sm-6 mb-3">
					<label className="m-0">{t('cp.sessionEnded')}</label>
					<input readOnly={readOnly || false} className="form-control form-control-sm" type="text" value={Settings.formatDate(session.transactionDateStopped, 'datetime')} />
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12 col-sm-6 mb-3">
					<label className="m-0">{t('cp.sessionDuration')}</label>
					<input readOnly={readOnly || false} className="form-control form-control-sm" type="text" value={Settings.formatDuration(session.transactionDuration ?? 0)} />
				</div>
				<div className="col-xs-12 col-sm-6 mb-3">
					<label className="m-0">{t('cp.kwhUsed')}</label>
					<input readOnly={readOnly || false} className="form-control form-control-sm" type="text" value={session.transactionEnergyUsed ?? 0} />
				</div>
			</div>

			<div className="row">
				<div className="col-xs-12 col-sm-6 mb-3">
					<label className="m-0">{t('cp.invoicedAmount')}</label>
					<input readOnly={readOnly || false} className="form-control form-control-sm" type="text" value={Settings.formatNumber(session.transactionBilledAmount ?? 0.0, 2)} />
				</div>
				<div className="col-xs-12 col-sm-6 mb-3">
					<label className="m-0">{t('cp.stopReasonCode')}</label>
					<input readOnly={readOnly || false} className="form-control form-control-sm" type="text" value={session.transactionStopReason} />
				</div>
			</div>
		</form>
	);
};

export default SessionInfoForm;
